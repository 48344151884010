import React from 'react';
import ReactDOM from 'react-dom';
import App from './default page react';
import Coba from './coba fetch'

ReactDOM.render(
  <React.StrictMode>
    {/* <App /> */}
    <Coba />
  </React.StrictMode>,
  document.getElementById('root')
);