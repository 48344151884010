import './index.css';

import React, { Component } from 'react'
import App from './App';

export default class index extends Component {
    render() {
        return (
            <div>
                <App />
            </div>
        )
    }
}
